#page-manage-campaigns {
  #campaign-details {
    background: white;
    padding: 10px;
    .details-wrap {
      .label {
        font-size: 12px;
      }
      .value {
        font-size: 16px;
      }
      @media screen and (max-width: 768px) {
        margin-top: 10px;
      }
    }
  }

  #campaign-applicants {
    .applicant {
      margin-top: 5px;
      background: white;
      padding: 10px;
      .user-details {
      }
      .twitter-page {
        padding-bottom: 5px;
        box-shadow: 0 10px 10px -15px #1890ff;
        .details-wrap {
          .label {
          }
          .value {
          }
        }
      }
      .instagram-page {
        padding-bottom: 5px;
        box-shadow: 0 10px 10px -15px #1890ff;
        .details-wrap {
          .label {
          }
          .value {
          }
        }
      }
    }
  }
}
