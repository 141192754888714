#page-home-influencers {
  .site-layout {
  }
  .home-page-block-info {
    background: aliceblue;
    /* box-shadow: 0 4px 80px #1890ff; */
    // box-shadow: 0 15px 10px -15px #1890ff;
    min-width: 33%;
    flex-basis: calc(559440px - 99900%);
    margin: 0.5rem;
    border-radius: 8px;
    padding: 1.3rem;
    font-size: 20px;
    margin-top: 40px;
    color: black;
    font-style: normal;
    // min-height: 220px;
  }
  #how-it-works {
    .title {
      text-align: center;
    }
    .block {
      // box-shadow: 0 15px 10px -15px #111;
      box-shadow: 0 15px 34px -15px #111;
      padding: 10px;

      .pre-icon {
        color: green;
        margin-right: 5px;
      }
      .heading {
        text-align: center;
      }
      .content {
        margin-top: 20px;
        padding: 5px;
        font-size: 18px;
      }
    }
    .links {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    #how-it-works {
      .block {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
