#page-influencers {
  .dashboard-influencers {
    margin: 0 auto;
    #instagram-page-view-wrapper {
      color: black;
      font-size: 20px;
      padding: 10px;
      margin-bottom: 20px;
      background: white;
      box-shadow: 0 15px 10px -15px #1890ff;

      .bio-page-link {
        margin-left: -16px;
        font-size: 16px;
      }
      .details-bio-wrap {
        padding: 10px;
        .user-fullname {
          font-size: 20px;
        }
        .user-bio {
          margin-top: 15px;
          font-size: 16px;
        }
        .user-website {
          margin-top: 10px;
          font-size: 14px;
        }
      }
      .span-label {
        margin-top: 10px;
        font-size: 22px;
      }
      .span-count {
        margin-top: 20px;
        font-size: 35px;
      }
      .details-wrap {
        text-align: center;
        .instagram-icon {
          font-size: 15px;
          margin-right: 5px;
        }
        .profile-image {
          height: 120px;
          box-shadow: 0 15px 10px -15px #1890ff;
        }
        .user-name {
          margin-top: 10px;
        }
      }
      .cost-wrap {
        .span-signin {
          font-size: 20px;
          margin-top: 30px;
        }
      }
      .instagram-page-link {
        font-size: 18px;
      }
      .button-edit-price {
        margin-left: -15px;
      }
    }

    @media screen and (max-width: 768px) {
      #instagram-page-view-wrapper {
        .ant-col {
          text-align: center;
          box-shadow: 0 15px 10px -15px #e7e9eb;
        }
        .span-label {
          font-size: 18px;
        }

        .span-count {
          margin-top: 0px;
          font-size: 35px;
        }
        .cost-wrap {
          .span-signin {
            font-size: 16px;
            margin-top: 15px;
          }
        }
      }
    }
    .my-instagram-pages {
      .instagram-page-details {
        font-size: 20px;
        background: white;
        box-shadow: 0 15px 10px -15px #1890ff;
        margin-bottom: 20px;
        .count {
          font-size: 18px;
          color: black;
        }
        .profile-image {
          height: 120px;
          width: 120px;
          box-shadow: 0 15px 10px -15px #1890ff;
        }
      }
    }
  }
}
