#header-main {
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 0px;
  background: white;
  box-shadow: 0 15px 10px -15px #111;

  .header-extra-link {
    margin-right: 20px;
  }
  @media screen and (max-width: 768px) {
    .header-extra-link {
      display: none;
    }
  }

  .logo-block {
    .logo {
      height: 30px;
      margin-left: 20px;
    }
    @media screen and (max-width: 768px) {
      .logo {
        height: 18px;
        margin-left: 15px;
      }
    }
  }

  .auth-block {
    text-align: right;
    padding-right: 20px;
    color: #1890ff;
    .header-auth-avatar {
      cursor: pointer;
      background: #1890ff;
    }
    .btn-header-login {
      color: #1890ff;
      font-size: 20px;
    }
    @media screen and (max-width: 768px) {
      .btn-header-login {
        font-size: 16px;
      }
      .header-auth-avatar {
        height: 30px;
        width: 30px;
      }
    }
  }
}
