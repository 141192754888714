#admin-page {
  .site-layout {
    margin-top: 70px;
  }
  #admin-sidebar-menu {
    // margin-top: 20px;
    // background: #1890ff;
    // padding-top: 21px;
    // flex: none !important;
    height: 100vh;
  }
  //   .admin-sidebar-menu-open {
  //     max-width: 250px !important;
  //     min-width: 250px !important;
  //     width: 250px !important;

  //     li {
  //       font-size: 20px;
  //     }
  //   }
  //   .admin-sidebar-menu-collapsed {
  //     max-width: 40px !important;
  //     min-width: 40px !important;
  //     width: 40px !important;
  //     .ant-menu-item {
  //       padding-left: 15px !important;
  //     }
  //   }
}
