#page-home-shortshout {
  .home-page-block-info {
    background: aliceblue;
    /* box-shadow: 0 4px 80px #1890ff; */
    box-shadow: 0 15px 10px -15px #1890ff;
    min-width: 33%;
    flex-basis: calc(559440px - 99900%);
    margin: 0.5rem;
    border-radius: 8px;
    padding: 1.3rem;
    font-size: 20px;
    margin-top: 40px;
    color: black;
    font-style: normal;
  }
  #info-influencers {
    .block {
      box-shadow: 0 15px 10px -15px #111;
      margin-top: 10px;
      .pre-icon {
        color: green;
        margin-right: 5px;
      }
      .heading {
        text-align: center;
      }
      .content {
        padding: 5px;
        font-size: 16px;
      }
    }
    .links {
      margin-top: 10px;
    }
  }

  .images {
    text-align: center;
    #image1 {
      padding: 0px;
      border-radius: 24px;
      margin-top: 40px;
      box-shadow: 0 15px 10px -15px #111;
      height: 500px;
      text-align: center;
    }
    #image2 {
      padding: 0px;
      border-radius: 24px;
      margin-top: 40px;
      box-shadow: 0 15px 10px -15px #111;
      height: 500px;
    }
  }

  @media screen and (max-width: 767px) {
    #info-influencers {
      .links {
        text-align: center;
      }
    }
  }
}
