.top-level-layout {
  min-height: 100vh;
}

.site-layout {
  padding: 0 50px;
  margin-top: 64px;
  @media screen and (max-width: 768px) {
    padding: 0 5px;
    margin-top: 64px;
  }
}

#page-title {
  font-size: 20px;
  margin-top: 20px;
  box-shadow: 0 15px 10px -15px #1890ff;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

#menu-influencers {
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    margin-top: 80px;
  }
}

#progress-bar-wrapper {
  background: #282525;
  position: fixed;
  top: 0;
  z-index: 999999999999;
  width: 100%;
  height: 100%;
  opacity: 0.5;

  .spinner {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    margin-top: 20%;

    .ant-spin-dot-item {
      background-color: white;
    }
    .ant-spin-text {
      color: white;
      font-size: 20px;
    }
  }
}
