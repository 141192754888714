#page-active-campaigns {
  #campaigns-list {
    .campaign-row {
      background: white;
      margin-top: 10px;
      padding: 10px;
      .details-wrap {
        .label {
          font-size: 12px;
          color: gray;
        }
        .value {
          font-size: 16px;
        }
        @media screen and (max-width: 768px) {
          margin-top: 10px;
        }
      }
    }
  }
}
