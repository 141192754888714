@import "~antd/dist/antd.css";

body {
  background: #f0f2f5;
  font-family: "Cairo", sans-serif !important;
  color: black;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dashboard-sample-view {
  padding-top: 50px;
}

.email-verification-block {
  margin-top: 80px;
  font-style: italic;
  color: red;
  font-weight: 700;
}

.add-username-block {
  margin-top: 150px;
  padding: 5px;
  width: 50%;
  margin: 0 auto;
}

.add-username-block .span-text {
  font-weight: bold;
  font-size: 20px;
  color: green;
}

.add-username-block .ant-input-group-addon {
  font-size: 16px;
}

.add-username-block .ant-input {
  font-size: 16px;
}

.managelinks-block {
  margin-top: 50px;
}

.recenlty-added-users-list {
  margin-top: 50px;
  padding: 10px;
}

.recenlty-added-users-list .title {
  margin-bottom: 20px;
}

.recently-added-user-avtar {
  margin-top: 20px;
  box-shadow: rgb(17 17 17) 0px 15px 10px -15px;
}

.my-stats {
  margin-bottom: 20px;
  background: white;
  padding: 20px;
  box-shadow: rgb(17 17 17) 0px 15px 10px -15px;
}

.dashboard-sample-view {
  text-align: center;
}

.shortshout-link-buttton {
  margin-bottom: 10px;
  background: #1890ff;
  cursor: pointer;
  box-shadow: 3px 5px #82a8c6;
}

.shortshout-link-buttton:active {
  box-shadow: none;
}

@media screen and (max-width: 767px) {
  .add-new-link-button {
    width: 100%;
  }
  .recenlty-added-users-list .title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .managelinks-block {
    margin-top: 0px;
  }
  .dashboard-sample-view {
    text-align: center;
  }
  .email-verification-block {
    margin-top: 130px;
  }

  .add-username-block {
    width: 100%;
    margin-top: 100px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sample-short-shout-box {
  /* border: 2px solid #1890ff; */
  border-radius: 10px;
  max-width: 330px;
  margin: 0 auto;
  height: 75vh;
  overflow-y: auto;
  box-shadow: rgb(17 17 17) 11px 25px 43px -17px;
}

.sample-short-shout-box::-webkit-scrollbar {
  display: none;
}

#header-main .btn-login-register {
  width: 150px;
  height: 40px;
}

.login-form-button {
  width: 150px;
  height: 40px;
}
@media screen and (max-width: 767px) {
  .login-form-button {
    width: 100%;
    height: 40px;
  }
  .btn-login-register {
    height: 32px !important;
    width: 90px !important;
  }
}

.ant-steps-item-title {
  font-size: 18px !important;
}
.ant-steps-item-description {
  color: black !important;
  font-size: 15px !important;
}

#public-shortshout-page {
  max-width: 50%;
}
@media screen and (max-width: 850px) {
  #public-shortshout-page {
    max-width: 100%;
  }
}

.dashboard-wrapper-tabs {
  margin-top: 10px;
}

.dashboard-wrapper-tabs .ant-tabs-nav-wrap {
  text-align: center;
}

@media screen and (max-width: 850px) {
  .dashboard-wrapper-tabs {
    margin-top: 65px;
  }
}

#form-update-password {
  width: 50%;
  margin: 0 auto;
}
#form-update-password h3 {
  text-align: center;
}
#form-update-password .form-password {
  background: white;
  padding: 10px;
}

.my-profile-user-info {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

@media screen and (max-width: 850px) {
  #form-update-password {
    width: 90%;
  }
  .my-profile-user-info {
    width: 100%;
  }
}

.google-login-button {
  width: 100%;
  background: #1890ff;
  color: white;
}

.shortshout-linktype-icon {
  position: absolute;
  left: 30px;
  font-size: 25px;
  top: 13px;
}

@media screen and (max-width: 768px) {
  .shortshout-linktype-icon {
    left: 10px;
    font-size: 20px;
  }
}

#header-google-login {
  display: none;
}
@media screen and (max-width: 768px) {
  #header-google-login {
    display: block;
    width: 92%;
    margin: 0 auto;
    margin-top: -26px;
  }
}
