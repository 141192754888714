#footer-block {
  // box-shadow: 0px 0px 10px 0px #111;
  // background: white;
  margin-top: 50px;
  //   background: #1890ff;
  //   color: white;
  text-align: center;
  //   margin-top: 200px;
  //   background: transparent;
  font-weight: bold;
  font-size: 16px;
  a {
    font-size: 20px;
    color: black;
  }
  .footer-social-icons {
    font-size: 24px;
    margin: 5px;
    margin-top: 10px;
    color: black;
  }
}
