#page-authRegisterPage {
  .register-root-wrapper {
    margin-top: 100px;
  }
  .form-register {
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 15px 10px -15px #1890ff;
    .form-heading {
      text-align: center;
      margin-bottom: 20px;
      box-shadow: 0 15px 10px -15px #1890ff;
    }
    .button-register {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .register-root-wrapper {
      margin-top: 80px;
    }
    .form-register {
      padding-left: 30px;
      padding-right: 30px;
      .ant-form-item {
        margin-bottom: 10px;
        .ant-form-item-label {
          margin-bottom: -15px;
        }
      }
    }
  }
}
